import DynamicLoader, { Placeholder } from 'poc-dynamic-wc-loader';
// import './search-bar';

const template = document.createElement('template');
template.innerHTML = `
<framework-boundary framework="angular" version="7.2.15">
    <navigation-wrapper>
    
    
        <!-- HOME PAGE -->
        <div slot="page_home">
            <framework-boundary framework="react" version="16.8.6">
                <search-bar></search-bar>
            </framework-boundary>
            <framework-boundary framework="lit-element" version="2.1.0">
                <product-list></product-list>
            </framework-boundary>
        </div>
        
        
        <!-- PRODUCT PAGE -->
        <div slot="page_product">
            <framework-boundary framework="lit-element" version="2.1.0">
                <product-details></product-details>
            </framework-boundary>
        </div>
        
        <!-- CHECKOUT PAGE -->
        <div slot="page_checkout">
            <div class="non-existing-page-container">
                You can't check out... this is not a real store.
            </div>
        </div>
        
        <!-- PROFILE PAGE -->
        <div slot="page_profile">
            <div class="non-existing-page-container">
                You don't have a profile
            </div>
        </div>
        
        
        <!-- SHOPPING CART DIV -->
        <div slot="shopping_cart">
            <framework-boundary framework="angular" version="6.1.10">
                <shopping-cart></shopping-cart>
            </framework-boundary>
        </div>
        
    </navigation-wrapper>
</framework-boundary>
`;

export class AppRoot extends HTMLElement {
    constructor() {
        super();
        // no shadow root is used here so styling can be inherited from the main page
        this.innerHTML = template.innerHTML;
        
        DynamicLoader.config.placeholder = Placeholder;
        DynamicLoader.config.repository = 'https://repo.markvlagen.nl/';
        DynamicLoader.config.exclude = [
          'mat-tooltip-component',
          'app-shopping-cart'
        ];
    
        this.loader = new DynamicLoader();
    }
    
    disconnectedCallback() {
        this.loader.stop();
    }

}
window.customElements.define('app-root', AppRoot);
