const template = document.createElement('template');
template.innerHTML = `
<style>
.boundary {
    box-shadow: none;
    transition: margin .5s;
    margin: auto;
}
.boundary.boundary-visible {
    overflow: hidden;
    box-shadow: 0 0 0 2px black;
    position: relative;
    border-radius: 4px;
    margin: 6px auto;
}
.boundary.boundary-visible:after {
    content: 'Unknown framework';
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 12px;
    text-transform: uppercase;
    color: #f9f9f9;
    background: black;
    padding: 4px 6px;
    border-radius: 6px 0 0 0;
}
.boundary.boundary-visible.boundary-angular {
    box-shadow: 0 0 0 2px #DD0031;
}
.boundary.boundary-visible.boundary-angular:after {
    background: #DD0031;
}
.boundary.boundary-visible.boundary-react {
    box-shadow: 0 0 0 2px #20232a;
}
.boundary.boundary-visible.boundary-react:after {
    background: #20232a;
}
.boundary.boundary-visible.boundary-lit-element {
    box-shadow: 0 0 0 2px #00A3E6;
}
.boundary.boundary-visible.boundary-lit-element:after {
    background: #00A3E6;
}
</style>
<style id="content"></style>
<div class='boundary'>
    <slot>Loading...</slot>
</div>
`;

export class Frameworkboundary extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(template.content.cloneNode(true));
        this.boundaryEl = this.shadowRoot.querySelector('.boundary');
        this.contentStylesEl = this.shadowRoot.getElementById('content');
    }

    connectedCallback() {
        const shown = this.getAttribute('show') !== null;
        shown ? this._show() : this._hide();
        this._setContent()
    }

    _setContent(type = {}) {
        const framework = type.framework || this.getAttribute('framework');
        const version = type.version || this.getAttribute('version');
        this._setFrameworkColor(framework);
        this.contentStylesEl.innerHTML = `
            .boundary.boundary-visible:after {
                content: '${framework}${version ? `   (v ${version})` : ''}';
            }`;
    }

    _setFrameworkColor(framework) {
        if(framework) {
            framework = framework.toLowerCase();
        }
        this.boundaryEl.classList.remove('boundary-angular', 'boundary-react', 'boundary-lit-element');
        if (framework === 'angular') {
            this.boundaryEl.classList.add('boundary-angular');
        } else if (framework === 'react') {
            this.boundaryEl.classList.add('boundary-react');
        } else if (framework === 'polymer' || framework === 'lit-element' || framework === 'litelement') {
            this.boundaryEl.classList.add('boundary-lit-element');
        }
    }
    _hide() {
        this.boundaryEl.classList.remove('boundary-visible');
    }
    _show() {
        this.boundaryEl.classList.add('boundary-visible');
    }

    static get observedAttributes() {
        return ['show', 'framework'];
    }
    attributeChangedCallback(name, oldValue, newValue) {
        switch (name) {
            case 'show':
                newValue !== null ? this._show() : this._hide();
                break;
            case 'framework':
                this._setContent({ framework: newValue });
                break;
            case 'version':
                this._setContent({ version: newValue });
                break;
        }
    }
    get show() {
        return this.hasAttribute('show');
    }
    set show(isShown) {
        if (isShown) {
            this.setAttribute('show', '');
        } else {
            this.removeAttribute('show');
        }
    }
    get framework() {
        return this.getAttribute('framework');
    }
    set framework(newValue) {
        this.setAttribute('framework', newValue);
    }
    get version() {
        return this.getAttribute('version');
    }
    set version(newValue) {
        this.setAttribute('version', newValue);
    }
}
window.customElements.define('framework-boundary', Frameworkboundary);
